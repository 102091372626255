@font-face {
    font-family: "Quicksand";
    font-weight: 500;
    src: url("./fonts/Quicksand-Medium.ttf") format("truetype");
}

stripe-payment-sheet {
    .payment-info {
        width: 100% !important;
    }

    .stripe-heading {
        white-space: nowrap;
        font-size: 19px !important;
        margin-bottom: 50px !important;
    }

    *,
    button {
        font-family: "Quicksand" !important;
        font-weight: 500;
    }

    button {
        background: #bba599;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
    }
}
