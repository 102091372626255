// IONIC BASICS
@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

// IONIC EXTRAS
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
//@import "@ionic/angular/css/ionic-swiper";

// AGGRID
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

// // Flags
// @import "../node_modules/flag-icon-css/sass/flag-icons.scss";

@import "intl-tel-input/build/css/intlTelInput.css";

// MY EXTERNALS
@import "./assets/icomoon.css";
@import "./assets/animate.css";

// MY INTERNALS
@import "./assets/stripe-modal-fix.scss";

/* Local fonts from Google :) */
@font-face {
    font-family: "Quicksand";
    font-weight: 300;
    src: url("./assets/fonts/Quicksand-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Quicksand";
    font-weight: 500;
    src: url("./assets/fonts/Quicksand-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Quicksand";
    font-weight: 600;
    src: url("./assets/fonts/Quicksand-SemiBold.ttf") format("truetype");
}

*,
input,
button {
    font-family: "Quicksand", Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.toolbar-ios {
    overflow: visible !important;
}

html {
    background-color: #fff2e5;
}

ion-router-outlet {
    background: rgb(255 247 239 / 40%);
}

body {
    background-color: #fff2e5;
    background-image: url("./assets/wallpaper.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 0 0;

    //animation: smoothBackgroundPosition 60s linear infinite;
}

@keyframes smoothBackgroundPosition {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 20%;
    }
}

html,
body {
    -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
    display: none;
}

ion-content {
    --background: rgb(255 255 255 / 40%);
    // --offset-bottom: auto !important;
    // --overflow: hidden;
    // overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}

.no-scroll {
    overflow: hidden !important;
    --overflow: hidden;
}

ion-tab-button {
    color: #e5d1c6;
}

.tab-selected {
    color: #bca599;
}

ion-item-options {
    border: none !important;
    margin-right: 24px;
}

ion-item-option {
    //height: 100px !important;
    margin-top: 22px !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

ion-refresher {
    z-index: 10 !important;
    --color: #b08d7b;

    * {
        color: #b08d7b;
    }
}

ion-item * {
    border: none !important;
}

ion-input,
ion-textarea {
    caret-color: #b08d7b !important;
}

// AG GRID BLUE SELECTED ROW FIX
.ag-row-selected,
.ag-row-selected::before {
    background-color: unset !important;
    background-image: none !important;
}

.ag-cell {
    line-height: 21px !important;
    padding-top: 9px;
}

.from_row {
    font-size: 8px;
    width: 70%;
    max-width: 130px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    padding: 4px;
    box-sizing: border-box;
    box-shadow: 1px 20px 16px 4px #6e6e6e;
}

.lg_card_grid {
    flex-wrap: wrap;
    justify-content: center;

    padding: 0 !important;
    margin-top: 40px;

    .card {
        width: 50%;
        max-width: 50%;
    }

    .cardimg {
        min-width: 100%;
        max-width: 100%;
        width: 100% !important;
    }
}

.card {
    display: block;
    margin-right: 8px;
    cursor: pointer;

    img {
        display: block;
        margin-top: -2px;
        margin-left: auto;
        margin-right: auto;

        min-width: 100px;
        min-height: 150px;

        max-width: 600px;
        max-height: 240px;

        object-fit: cover;
        object-position: center;

        background: #fafafa;
        transition: border-radius 1s;
        // background-image: url("assets/loader.webp");
        // background-size: cover;
        // background-position: center;

        border-radius: 6px;
    }

    .promo_badge {
        width: 40px;
        height: 40px;
        position: relative;
        min-height: inherit;
        min-width: inherit;
        margin-top: 11px;
        margin-bottom: -52px;
        //margin-right: 11px;
        float: right;
        object-fit: contain;
        mix-blend-mode: multiply;
        background-image: none;
    }

    .card_content {
        text-align: center;
        border-top: 0px;
        padding: 6px;
        //margin-top: -5px;

        color: #5d5d5d;

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        margin-left: auto;
        margin-right: auto;

        transition: all 0.5s ease;

        .card_name {
            font-size: 10px;
            display: block;

            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
        }
        .true_name {
            height: fit-content;
            font-size: 14px;
            font-weight: bold;
            margin: auto;
            padding-left: 10px;
            padding-right: 10px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 80%;
            max-width: 100px;
            display: block;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .true_date {
            font-size: 12px;
        }

        .card_price {
            margin-top: 20px;
            //margin-top: -50px;
            .tcg_price {
                color: #db7878;
                text-decoration: line-through;
                font-weight: 100;
                font-size: 12px;
            }

            .sale_price {
                color: #799d79;
                font-weight: 700;
                font-size: 14px;
                margin-top: 3px;
            }

            span {
                font-size: 10px;
            }

            .red {
                color: #db7878;
            }
        }

        .big_button {
            background: #091f47;
            width: 100%;
            padding: 10px;
            margin-top: 10px;
            color: #fff;
        }
    }

    .open_content {
        margin-top: -30px;
    }

    .listedTime {
        text-align: center;
        font-size: 8px;
        width: 100%;
        display: block;
        margin-top: 3px;
        margin-bottom: 3px;
        color: #9e9e9e;
        font-weight: bold;
        ion-icon {
            font-size: 6px;
        }
    }
}

.card:hover img {
    border-radius: 0px;
}

.iti {
    display: inline-flex;
    min-height: 47px;
    margin: 10px 0;

    input {
        outline-width: 0 !important;
        background: none;
    }
}

.minimal_grid .card {
    max-width: 48%;

    img {
        max-width: 100%;
    }
}

.swipe_slide {
    height: auto;

    padding: 30px 70px;
    box-sizing: border-box;
    transition: background-color 1s ease;
    box-shadow: inset 0 0 40px 40px rgb(254 249 242);

    img {
        width: 100%;
        height: 100%;
        min-height: 300px;

        min-width: 200px;
        max-width: 500px;
        margin: auto;
        display: block;

        border-radius: 21px;
        object-fit: contain;
    }
}

.tags {
    margin-top: 10px;

    ion-chip {
        margin: 0;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 7px 11px;

        color: #d4c3ba;
        border-color: #d4c3ba;
        height: fit-content;
    }

    .filled {
        background: #bba599;
        color: #fff;
    }

    ion-label {
        font-size: 12px;
    }

    ion-icon {
        color: #fff;
        --color: #fff;
    }

    .promo {
        color: #ffffff;
        background: #ecb389;
        border-color: #c79c79;
    }
}

.global_bar {
    ion-title {
        color: #e7ccb8;
        font-weight: bold;
    }

    ion-icon {
        cursor: pointer;
    }
}

.invalid {
    background: #ffe8e8 !important;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 4px;
}

.section {
    width: 100%;
    height: fit-content;
    //padding: 12px 18px;
    box-sizing: border-box;
    background: #fff3e4e8;
    margin-bottom: 10px;
    z-index: 100;
    overflow: hidden;

    box-shadow: inset 0px 0px 20px #6f412852;
    //box-shadow: 0px 5px 10px #d5d5d5;

    .section_top {
        display: inline-flex;
        //justify-content: space-between;
        padding-bottom: 10px;
        width: 100%;

        .section_title {
            font-size: 16px;
            margin-left: 18px;
            color: #ac8875;
            text-transform: uppercase;
            opacity: 0.7;
            font-weight: 900;
            //letter-spacing: 4px;
        }

        .all_btn {
            border: 1px solid #ac8875;
            padding: 6px;
            height: -moz-fit-content;
            height: fit-content;
            border-radius: 4px;
            opacity: 0.7;
            background: none;

            color: #ac8875;
            font-size: 10px;
            font-weight: 900;
            text-transform: uppercase;

            margin-top: 19px;
            margin-left: 10px;

            ion-icon {
                height: 8px;
            }
        }
    }

    .stacked_top {
        display: block;

        .top_row {
            display: inline-flex;
        }

        .additonal_tags {
            margin: 0 !important;
            margin-bottom: 8px !important;
            margin-left: 15px !important;
        }
    }
}

.last_section .section {
    margin-bottom: 0;
}

.promo_section {
    width: 96%;
    margin: 5px auto;
    margin-bottom: 10px;
    border-radius: 6px;
    border: 2px solid #ecc2b6;
}

.secondary {
    color: #ac8875;
    background: #fff1e5;
}

.card_grid {
    display: inline-flex;
    //justify-content: space-between;

    overflow-x: scroll;
    //overflow-y: hidden;

    width: 100%;
    //padding-top: 15px;
    padding: 5px 10px;
    padding-bottom: 20px;
}

.tan_tab textarea {
    padding: 3px 0 !important;
    height: fit-content !important;
}

.card_searcher .searchbar-input {
    font-size: 12px !important;
}

.card_searcher .searchbar-search-icon {
    width: 16px;
    padding-left: 5px;
}

.round_title {
    font-size: 12px;
    text-transform: uppercase;
    color: #b08d7b;
    font-weight: 900;
}

.facebook {
    position: absolute;
    bottom: 20px;
    left: 40px;
    font-size: 46px;
    color: #3b5998;
}

.additonal_tags {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    margin: 0;
    margin-left: 5px;
    margin-bottom: 20px;

    .tag {
        color: #fff;
        line-height: 12px;
        font-size: 11px;
        font-weight: bold;
        display: inline-flex;
        text-transform: uppercase;
        padding: 6px 12px;
        border-radius: 3px;
        cursor: pointer;

        ion-icon {
            color: #fff;
            display: block;
            margin-top: 0px;
            margin-right: 4px;
        }
    }
}

ion-modal {
    --background: #fff3e4 !important;
}

ion-item,
ion-list-header {
    background: #fff8ef !important;
    //background: #fff3e4 !important;
    --background: #ffffff00 !important;
    --background-activated: #fff3e4 !important;
    //border: 1px solid #ecd7c2;
    color: #c28d79;
    --border-color: transparent;
}

ion-label {
    font-family: "Quicksand", sans-serif !important;
}

// ion-card {
//     ion-item {
//         border: none;
//     }
// }

// ion-card-content {
//     background: #dbb49f82;
//     color: #977e70;
// }

// ion-toolbar {
//     --background: white;
// }

.phone .ion-page {
    height: 100%;
    width: 385px;
    margin: auto;
}

.profile_row .stars {
    font-size: 14px;
}

.stars {
    font-size: 9px;
    display: inline;
    color: #e8985d;
}

ion-toolbar {
    --background: #fff3e5f2;
    //border-bottom: 2px solid #f1cabb;
    --border-width: 0px !important;
    border: none;

    ion-icon {
        color: #977e70;
    }
}

.auto_putter {
    border: none;
    background: none;
    width: 100%;

    padding: 5px;
    padding-left: 0px;
    margin-bottom: 5px;
}

.keyboardBlock {
    height: 40vh;
    width: 100%;
}

.upload_bar {
    border: 2px solid #ac8875 !important;
    color: #ac8875;
    --color: #ac8875 !important;
    border-radius: 3px;

    ion-title {
        color: #ac8875 !important;
        --color: #ac8875 !important;
    }
}

.filled_btn {
    background: none;
    border: none;
    color: #fff3e4;
    --background: #ac8875;
    background: #ac8875;
    ion-title {
        color: #fff1e5 !important;
    }
}

.filled {
    color: #fff3e4;
    --background: #ac8875;

    ion-title {
        color: #fff1e5 !important;
    }
}

.rev_text {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    color: #ac8875;
    font-size: 14px;
}

.empty_toast {
    opacity: 0.6;
    pointer-events: none;
    margin-top: 100px;
}

// .blue {
//     background: #ac8875 !important;
//     --background: #ac8875 !important;

// }

ion-header ion-toolbar:first-child {
    padding-left: 10px !important;
    padding-right: 10px !important;
    //padding-bottom: 10px !important;
}

ion-modal ion-header ion-toolbar:first-child {
    padding: 20px 10px !important;
}

.location_bar {
    --background: #f0b78c;
    border-bottom: 2px solid #fff1e5;
    position: relative !important;
    cursor: pointer;

    ion-label {
        cursor: pointer;
        display: inline-flex;
        grid-gap: 5px;
        line-height: 34px;
    }

    ion-icon {
        font-size: 28px;
        margin-left: 0px;
        margin-top: 2px;
        display: block;
        color: #fff1e5;
    }

    span {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        display: block;
        color: #fff1e5;
    }
}

.imgCell {
    padding: 0;
    padding-top: 15px;
}

.center_spinner {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
    margin-top: 10vh;

    ion-spinner {
        width: 50px;
        height: 50px;
        --color: #525e74;
    }
}

.down_text {
    font-size: 14px;
    color: #ba9f8c;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-top: 120px;
}

ion-spinner {
    --color: #ac8875 !important;
    color: #ac8875 !important;
}

.iti {
    width: 100%;
}

.uppercase_alert_input {
    text-transform: uppercase;
}

.prop_offer .alert-input-wrapper {
    display: inline-flex;
    width: 80%;
    grid-gap: 5px;

    input {
        margin-top: -6px;
    }
}

.inner_card_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    place-items: center;
    grid-gap: 2px;
    height: 450px;

    overflow-x: scroll;
    overflow-y: hidden;

    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 20px;

    .card_square {
        width: 100px;
        height: 33%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            cursor: pointer;
            border-radius: 4px;

            // background-image: url("/assets/loader.webp");
            background-size: cover;
            background-position: center;
        }
    }

    .square img {
        object-fit: contain;
    }
}

.square img {
    height: auto !important;
    //object-fit: contain;
    //padding: 4px;
}

.cardimg {
    // background-image: url("/assets/loader.webp") !important;
    background-size: cover;
    background-position: center;
}

.connecting_toast {
    margin-top: -51px;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --background: #fff1e5;
    --color: #ac8875;
}

.infinite-loading {
    display: block !important;
}

@keyframes barMotion {
    0% {
        right: 100%;
    }

    50% {
        right: 0%;
    }

    100% {
        right: -100%;
    }
}

.bar,
.loading_block {
    background: #f2e4da;
    display: block;
    height: 8px;
    width: 100%;
    position: relative;
    animation-name: barMotion;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.bar_wrap {
    position: relative;
    width: 100%;
    height: 8px;
    background: #fff1e5;
    overflow: hidden;
}

.outer_loading_wrap {
    padding: 20px;

    .loading_wrap {
        position: relative;
        width: 100%;
        height: 100px;
        border-radius: 8px;
        margin-bottom: 20px;
        overflow: hidden;
        background: #fcf5ef;

        .loading_block {
            display: block;
            width: 100%;
            height: 130px;
            border-radius: 8px;
            background: #fff7f0;
        }
    }
}

/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

//QR SCANNER CSS CUSTOM

.qrscanner {
    opacity: 0;
    background: transparent;
}

.scanner-ui {
    display: block;
    width: 100vw;
    height: 100vh;
}

.scanner-box {
    width: 250px;
    height: 250px;
    border: 3px solid #9f8577;

    top: 30%;
    transform: translateY(-50%);

    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 90000;

    span {
        width: 100%;
        height: 3px;
        background: #9f8577;
        display: block;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.upload_toolbar {
    padding: 10px;
    cursor: pointer;
}

.bottom-options {
    position: absolute;
    bottom: 0;
    height: 80px;
    width: 100%;
    background: #fff;
    color: #9f8577;
    z-index: 90000;

    h3 {
        text-align: center;
        font-size: 21px;
        width: fit-content;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
// END OF QR SCANNER CSS CUSTOM

.cnja_popover {
    &::part(arrow) {
        --background: #fff3e4;
    }
}

// // DESKTOP
// @media only screen and (min-width: 500px) {
//     ion-app {
//         position: relative !important;
//         contain: none !important;
//         width: 390px !important;
//         height: 812px !important;
//         margin-left: auto;
//         margin-right: auto;

//         border: 2px solid black;

//         margin-bottom: 3%;
//         margin-top: 4vh;
//     }

//     ion-modal {
//         --width: 100%;
//         --height: 100%;
//     }

//     body {
//         background-size: cover;
//         background-position: center;
//         border: 4px solid black;
//     }
// }
