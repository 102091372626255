:root {
  --ion-color-primary: #bca599;
  --ion-color-primary-contrast: #fff3e4;
  --ion-color-primary-shade: #cfbfb7;
  --ion-color-primary-tint: #dfc7bb;
  --swiper-theme-color: #bca599;

  .from_row {
    background: #b9b9b9;
  }

  .magic {
    background: #e7bc91;
  }

  .pokemon {
    background: #ecc2b6;
  }

  .fab {
    background: #e8985d;
  }

  .yuGiOh {
    background: #a07964;
  }

  .dbz {
    background: #eda76d;
  }

  .ff {
    background: #decfcb;
  }

  .metazoo {
    background: #d7af99;
  }

  .other_game {
    background: #7f5539;
  }

  .pending {
    background: #eab658 !important;
  }
  .shipped {
    background: #2ea26a !important;
  }
}
